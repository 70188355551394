import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import * as z from 'zod';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Define the Zod schema for form validation
const schema = z.object({
  name: z.string().min(5, { message: 'Please enter your full name.' }),
  phoneNumber: z.string().regex(/^\d{10}$/, { message: 'Please enter a valid 10-digit phone number.' }),
  email: z.string().email({ message: 'Please enter a valid email address.' }),
  message: z.string().min(20, { message: 'Your message must be at least 20 characters long.' }),
});

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const links = [
    { name: 'Home', path: '/' },
    { name: 'Features', path: '/features' },
    { name: 'Team', path: '/teams' },
    { name: 'Contact', path: '/contact' },
    { name: 'Join Team', path: '/join-team' },
  ];

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  // Form submission
  const onSubmit = async (data) => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    try {
      const response = await axios.post('https://sheetdb.io/api/v1/qvfqwmj4aojv8', {
        data: [
          {
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
            message: data.message,
          },
        ],
      });

      if (response.status === 201 || response.status === 200) {
        toast.success('Demo request submitted successfully!');
        setIsModalOpen(false); // Close the modal upon successful submission
      } else {
        toast.error('Failed to submit the demo request. Please try again.', { closeOnClick: true });
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`, { closeOnClick: true });
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  // Effect to update active link based on route
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="flex justify-between items-center px-4 py-4 md:px-6 md:py-6 fixed top-0 w-full z-50 bg-[#f6f9fc] shadow-md">
      <Helmet>
                  <title>Vital Health Solutions</title>
                  <meta name="description" content="Vital Health Solutions is a leading healthcare solutions company specializing in personalized healthcare services." />
                </Helmet>

        <div>
          <Link to="/">
            <img src="/navbar.png" className="w-40 md:w-56" alt="Logo" />
          </Link>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex space-x-6 text-lg">
          {links.map((link) => (
            <Link
              to={link.path}
              key={link.name}
              className={`cursor-pointer hover:underline ${
                activeLink === link.path ? 'text-blue-600 font-bold underline' : ''
              }`}
            >
              {link.name}
            </Link>
          ))}
        </div>

        {/* Request Demo button and Menu for Mobile */}
        <div className="flex items-center space-x-4">
          <button
            className="navbarbutton btn hidden md:flex items-center text-sm md:text-lg justify-center py-2 px-4 md:px-6 border-2 border-color-blue rounded transition duration-300"
            onClick={toggleModal}
          >
            Request Demo
            <i className="fa-solid fa-arrow-right ml-2 transition-all duration-300 transform"></i>
          </button>

          {/* Mobile Menu Icon */}
          <button
            className="md:hidden text-3xl"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <i className="fa-solid fa-xmark"></i> : <i className="fa-solid fa-bars"></i>}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden fixed top-0 left-0 w-full text-lg bg-white rounded-b-3xl z-50 flex flex-col justify-between p-6">
          <div className="flex justify-between items-center mb-6">
            <a href='/'>
              <img src="/navbar.png" className="w-40" alt="Logo" />
            </a>
            <button className="text-3xl" onClick={() => setIsMenuOpen(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="flex flex-col space-y-6">
            {links.map((link) => (
              <Link
                to={link.path}
                key={link.name}
                onClick={() => setIsMenuOpen(false)}
                className={`cursor-pointer hover:underline ${
                  activeLink === link.path ? 'text-blue-600 font-bold underline' : ''
                }`}
              >
                {link.name}
              </Link>
            ))}
            <button
              className="navbarbutton btn flex w-1/2 mx-auto items-center text-[16px] justify-center py-2 px-4 border-2 border-color-blue rounded transition duration-300"
              onClick={toggleModal}
            >
              Request Demo
              <i className="fa-solid fa-arrow-right ml-2 transition-all duration-300 transform"></i>
            </button>
          </div>
        </div>
      )}

      {/* Modal for Request Demo */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center px-5 bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-xl">
            <div className="flex justify-end">
              <button
                className="text-color-blue hover:text-[#1152ad] focus:outline-none"
                onClick={toggleModal}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <h2 className="text-center text-2xl font-bold text-color-blue mb-4">Schedule a Demo</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <input
                    {...register('name')}
                    type="text"
                    placeholder="Full Name"
                    className={`w-full p-3 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring-purple-500 focus:border-purple-500`}
                  />
                  {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
                </div>
                <div className="w-1/2">
                  <input
                    {...register('phoneNumber')}
                    type="text"
                    placeholder="Phone number"
                    className={`w-full p-3 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring-purple-500 focus:border-purple-500`}
                  />
                  {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber.message}</p>}
                </div>
              </div>
              <div>
                <input
                  {...register('email')}
                  type="email"
                  placeholder="Email"
                  className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring-purple-500 focus:border-purple-500`}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
              </div>
              <div>
                <textarea
                  {...register('message')}
                  placeholder="Your message"
                  className={`w-full p-3 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-md focus:ring-purple-500 focus:border-purple-500`}
                  rows="4"
                />
                {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message.message}</p>}
              </div>
              <div className='flex justify-center'>
              <button
                type="submit"
                disabled={isSubmitting}
                className=" px-6  btn  py-3 rounded-md focus:outline-none transition-colors duration-300"
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
